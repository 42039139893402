<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('users') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">{{ $t('dashboard') }}</li>
                     <li class="breadcrumb-item active">{{ $t('users') }}</li>
                     <li v-if="isEditing" class="breadcrumb-item active">{{ $t('edit_user') }}</li>
                     <li v-else class="breadcrumb-item active">{{ $t('create_user') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-12">
                  <div class="card card-outline card-info">

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else>
                        <!-- card-header -->
                        <div class="card-header">
                           <h3 class="card-title">
                              {{ isEditing === false ? $t('create_user') : $t('edit_user') }}
                           </h3>
                        </div>

                        <form @submit.prevent="save">
                           <div class="card-body">
                              <div class="row">
                                 <div class="col-6">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">Nome</label>
                                       <input type="text" v-model="user.displayName" class="form-control" placeholder="Nome">
                                    </div>
                                 </div>

                                 <div class="col-6">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">E-mail</label>
                                       <input type="text" v-model="user.email" class="form-control" placeholder="E-mail">
                                    </div>
                                 </div>

                                 <div v-if="!isEditing" class="col-5">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">Senha</label>
                                       <input type="password" v-model="user.password" class="form-control" placeholder="Senha">
                                       <span
                                          class="ml-1"
                                          style="font-size: 10px; color: grey; font-style: italic"
                                       >
                                             {{ $t('this_should_be_a_temporary_password_to_change_it_the_user_simply_has_to_go_to_forgot_password_to_change_the_password') }}
                                       </span>
                                    </div>
                                 </div>

                                 <div class="col-4">
                                    <div class="form-group">
                                       <label>Perfil</label>
                                       <select v-model="user.profile" class="form-control">
                                          <option disabled value="">{{ $t('choose_one_profile') }}</option>
                                          <option
                                             :class="[{'selected': selectedProfile(`${user.profile}`)}]"
                                             value="admin"
                                          >
                                             {{ $t('admin') }}
                                          </option>
                                          <option
                                             :class="[{'selected': selectedProfile(`${user.profile}`)}]"
                                             value="employee"
                                          >
                                             {{ $t('employee') }}
                                          </option>
                                          <option
                                             :class="[{'selected': selectedProfile(`${user.profile}`)}]"
                                             value="partner"
                                          >
                                             {{ $t('partner') }}
                                          </option>
                                       </select>
                                    </div>
                                 </div>

                                 <div class="col-2 mt-3">
                                    <div class="form-group">
                                       <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                          <input type="checkbox" v-model="user.isActive" class="custom-control-input" id="customSwitch3">
                                          <label class="custom-control-label" for="customSwitch3">
                                             {{ user.isActive == true ? $t('active') : $t('disabled') }}
                                          </label>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                              <!-- ./row -->
                           </div>
                           <div class="card-footer">
                              <div class="row justify-content-end">
                                 <SaveButton
                                    :buttonName="saveButtonName"
                                    :isSaving="isSaving"
                                 >
                                 </SaveButton>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <!-- /.col-->
            </div>
            <!-- ./row -->
         </div>
      </section>
   </div>
</template>

<script>
import userService from "../services/user-service";
import { useToast } from "vue-toastification";
import SaveButton from "../../../components/SaveButton.vue";

export default {
   name: "UsersForm",
   setup() {
      const toast = useToast()
      return { toast }
   },
   components: {
      SaveButton
   },
   data () {
      return {
         user: {
            displayName: null,
            email: null,
            profile: null,
            isActive: false,
            password: null
         },
         oldEmail: null,
         isLoading: false,
         isEditing: false,
         isSaving: false,
         errors: []
      }
   },
   computed: {
      saveButtonName () {
         if (this.isEditing)
            return 'edit';
         else
            return 'save';
      }
   },
   async created() {
      if (this.$route.params.email){
         this.isEditing = true;

         this.isLoading = true;
         try {
            const { data: response } = await userService.get(this.$route.params.email);
            this.user = response;

            this.oldEmail = response.email;
         } finally {
            this.isLoading = false;
         }
      }
   },
   methods: {
      async save() {
         this.errors = [];
         this.isSaving = true;

         try {
            if (this.isEditing){
               await userService.edit(this.user, this.oldEmail);
               this.toast.success(this.$t("updated"));
            }
            else {
               await userService.create(this.user)
               this.toast.success(this.$t("saved"));
            }
            this.$router.push({ name: 'users' });
         } catch (error) {
            const { status } = error.response;
            const errorResponse = error.response.data.errors;

            if (this.isEditing)
               this.toast.error(this.$t("update_failure"));
            else
               this.toast.error(this.$t("save_failure"));

            if (status === 422){
               this.errors = Object.assign(this.errors, errorResponse)
               Object.keys(this.errors).forEach((field) => {
                  this.errors[field].forEach((error) => {
                     this.toast.error(error);
                  });
               });
            }
         }
         finally {
            this.isSaving = false;
         }
      },
      selectedProfile (profile) {
         return this.user.profile === profile
      },
   }
}
</script>


